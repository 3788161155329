<template>
  <div class="mb-20 pt-20">
    <div class="max-w-screen-xl mx-auto flex flex-col justify-center items-center">
      <h1 class="text-4xl max-w-screen-sm text-center my-8">Prestations proposées</h1>
      <div class="p-4">
        <ul data-aos="fade-left" class="flex flex-col space-y-4 text-left list-disc p-4">
          <li>Cours de pêche avec guide de pêche professionnel (diplômé)</li>
          <li>Mise à disposition du bâteau</li>
          <li>Prêt de matériel haut de gamme pour toutes les techniques</li>
          <li>Petites fournitures et appâts</li>
          <li>Boissons offertes</li>
          <li>Repas offert pour les sorties à la journée</li>
        </ul>
      </div>
      <p class="text-xl my-8 text-center"><span>VOUS VENEZ LES MAINS DANS LES POCHES</span><br><span class="underline decoration-4 decoration-blue-400">ON S’OCCUPE DE TOUT</span></p>
      <div class="flex justify-center items-center">
        <img src="@/assets/outline_info_black_24dp.png" alt="info" class="mr-2">
        <p>Pour les séjours, tout est compris !</p>
      </div>
      <div class="space-y-8 my-8">
        <div data-aos="fade-up" class="flex flex-col justify-center items-center">
          <h2 class="text-xl my-4">LE BATEAU EN MER</h2>
          <p>VEDETTE HATURIERE 7m80 OCQUETEAU avec Double Motorisation 2X 140CV 4T</p>
          <img src="@/assets/vedette_mer.jpg" alt="bateau mer" class="aspect-auto w-[350px] rounded-sm lg:w-auto lg:h-auto mt-4">
        </div>
        <div data-aos="fade-up">
          <h2 class="text-xl my-4">LE BATEAU EN EAU DOUCE</h2>
          <p>LE BATEAU EN EAU DOUCE OPEN TITANIUM 4m50 avec Moteur TORQEEDO Electrique (législation Lac Esparron)</p>
        </div>
        <div data-aos="fade-up">
          <h2 class="text-xl my-4">MATERIEL MIS A DISPOSITION :</h2>
          <ul>
            <li>Ensemble pêche aux leurres</li>
            <li>Ensemble buscle pêche daurades</li>
            <li>Ensemble pêche thon broumé/traine</li>
            <li>Ensemble pêche jig/madai/tenya</li>
            <li>Ensemble pêche de la liche</li>
            <li>Ensemble pêche bonite</li>
          </ul>
        </div>
        <div data-aos="fade-up" class="flex justify-center">
          <h2>SPONSORS : </h2><p class="ml-1">DAIWA – SHIMANO – DAM - COLMIC</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>